import request from '@/utils/request'

// api地址
const api = {
  corpContentId: '/frm/corpContent/'
}

/**
 * 根据corpId查询
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function corpContentId(id) {
  return new Promise((resolve, reject) => {
    request.get(api.corpContentId + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
